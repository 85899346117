import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import ListBeverage from '../list/Beverage';
import Row from '../list/Row';
import Preferred from '../list/Preferred';
import TastingList from '../list/TastingList';
import { useCart } from '../../utils/contexts/cart-context';
import TastingMenu from '../menu/TastingMenu';

function RedCafeTabs(props) {
    const { hasOrders } = props;
    const { restaurant } = useRestaurant();
    const { t } = useTranslation();
    const { add } = useCart();

    let def;
    if (restaurant.tasting_menu.length === 0) { // TODO: check
        def = 0;
    } else {
        def = restaurant.tasting_menu.length;
    }

    if (restaurant.courses.length === 0) {
        def = restaurant.tasting_menu.length + 1;
    }

    const [activeTab, setIsActiveTab] = useState(def);

    function renderTab() {
        return <TabList>
            <div className="tabs relative">
                <div className="bar overflow-x-scroll flex mx-3 mb-2 py-4">
                    {restaurant.tasting_menu.map((t, i) => (
                        <Tab key={i} index={i} label={t.name} translate={false}>
                            <div className={clsx("tab", "cursor-pointer", "inline-block", "mx-2", "py-1", "px-4",
                                "rounded-full", "border", "border-solid", "border-mycia-blue",
                                "whitespace-nowrap", { "active bg-mycia-blue tab-active text-white": activeTab === i })}>{t.name}
                            </div>
                        </Tab>
                    ))}
                    <Tab>
                        <div className={clsx("tab", "cursor-pointer", "inline-block", "mx-2", "py-1", "px-4",
                            "rounded-full", "border", "border-solid", "border-morgana-gold", "text-white",
                            "whitespace-nowrap", { "active bg-mycia-blue tab-active text-white": activeTab === restaurant.tasting_menu.length + 0 })}>{"CAFFETTERIA & BEVERAGE"}
                        </div>
                    </Tab>
                    <Tab>
                        <div className={clsx("tab", "cursor-pointer", "inline-block", "mx-2", "py-1", "px-4",
                            "rounded-full", "border", "border-solid", "border-mycia-blue",
                            "whitespace-nowrap", { "active bg-mycia-blue tab-active text-white": activeTab === restaurant.tasting_menu.length + 1 })}>FOOD
                        </div>
                    </Tab>
                    <Tab>
                        <div className={clsx("tab", "cursor-pointer", "inline-block", "mx-2", "py-1", "px-4",
                            "rounded-full", "border", "border-solid", "border-mycia-blue",
                            "whitespace-nowrap", { "active bg-mycia-blue tab-active text-white": activeTab === restaurant.tasting_menu.length + 2 })}>VINI
                        </div>
                    </Tab>
                    <Tab>
                        <div className={clsx("tab", "cursor-pointer", "inline-block", "mx-2", "py-1", "px-4",
                            "rounded-full", "border", "border-solid", "border-morgana-gold", "text-white",
                            "whitespace-nowrap", { "active bg-mycia-blue tab-active text-white": activeTab === restaurant.tasting_menu.length + 3 })}>{t("tab.button_3")}
                        </div>

                    </Tab>
                </div>
            </div>
        </TabList>
    }

    function renderTabCoursesContent() {
        return <>
            <TabPanel>
                <div className="beverages">
                    {(restaurant.beverages && restaurant.beverages.length > 0) &&
                        restaurant.beverages.map((beverage, i) => <ListBeverage beverage={beverage} index={i} key={i} />)}
                </div>
            </TabPanel>
            <TabPanel>
                {(restaurant.courses && restaurant.courses.length > 0) ?
                    restaurant.courses.map((course, i) => <Row course={course} index={i} key={i} />) : <p className="text-center">Nessun piatto disponibile</p>}
            </TabPanel>
            <TabPanel>
                <div className="wines">
                    {(restaurant.wines && restaurant.wines.length > 0) &&
                        restaurant.wines.map((winesCourse, i) => <ListBeverage beverage={winesCourse} index={i} key={i} />)}
                </div>
            </TabPanel>
        </>
    }

    return (
        <Tabs defaultIndex={def} onSelect={(i) => setIsActiveTab(i)}>

            {renderTab()}

            {/* tabs content render here*/}
            {hasOrders ?
                restaurant.has_tasting_menu && restaurant.tasting_menu.map(tastingMenu => (
                    <TabPanel>
                        {tastingMenu.courses.map((course, i) => (
                            <TastingList course={course} index={i} key={i} tastingMenu={tastingMenu} />
                        ))}
                        {tastingMenu.courses.length !== 0 && <div className="cta-tasting-menu text-center py-2 px-4 m-4 bg-mycia-green rounded-full">
                            <button type="button" onClick={() => add(tastingMenu, 1)}>Ordina il menu</button>
                        </div>}
                    </TabPanel>
                ))
                :
                restaurant.has_tasting_menu && restaurant.tasting_menu.map(tastingMenu => (
                    <TabPanel>
                        {tastingMenu.courses.map((course, i) => (
                            <TastingMenu course={course} index={i} key={i} tastingMenu={tastingMenu} />
                        ))}
                    </TabPanel>
                ))
            }

            {renderTabCoursesContent()}

            <TabPanel>
                <Preferred />
            </TabPanel>
            {/* -------------------- */}

        </Tabs >
    )
}

export default RedCafeTabs;
